@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .App {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0.0;
  }

  to {
    opacity: 1.0;
  }
}

.grid-select {
  cursor: pointer;
}

.import-status-not-updated {
  background-color: transparent;
}
.import-status-create {
  background-color: rgb(183, 223, 185);
}
.import-status-update {
  background-color: rgb(255, 213, 153);
}
.import-status-delete {
  background-color: rgb(250, 179, 174);
}
.import-status-delete:hover {
  background-color: rgb(249, 161, 154);
}
.import-status-update:hover {
  background-color: rgb(255, 203, 127);
}
.import-status-create:hover {
  background-color: rgb(165, 215, 167);
}
.import-status-not-updated:hover {
  background-color: rgb(234, 234, 234);
}
.import-status-not-imported {
  background-color: rgb(154, 200, 250);
}
.import-status-not-imported:hover {
  background-color: rgb(124, 170, 220);
}

.import-value-required {
  font-weight: bold;
  color: red;
  cursor: pointer;
}

.size-diff-btn {
  font-size: 20px;
}

.grid-toolbar-quick-search {
  margin: 1rem;
  margin-left: 80px;
}

.size-chart-upload-dialog .MuiFormControl-root {
  vertical-align: unset;
}

.goto-button {
  padding: 4px;
}

.copy-status-warning {
  background-color: rgb(255, 153, 153);
}

@keyframes shake {
  40%, 60% {
    transform: translate3d(-1px, 0, 0);
  }

  43%, 57% {
    transform: translate3d(2px, 0, 0);
  }

  46%, 50%, 54% {
    transform: translate3d(-4px, 0, 0);
  }

  48%, 52% {
    transform: translate3d(4px, 0, 0);
  }
}
